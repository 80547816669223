$darkest: #1e3120;

* {
  font-family: "Montserrat", sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h1 {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: $darkest;
  margin: 0 !important;

  @media (min-width: 768px) {
    font-size: 30px;
  }
}

h2 {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $darkest;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 1.33;
  }
}

h3 {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: $darkest;
  margin-top: 0;
  margin-bottom: 0;

  & + h1 {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    font-size: 30px;
  }
}

p {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $darkest;

  @media (min-width: 768px) {
    font-size: 13px;
  }

  strong {
    font-weight: bold;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
    color: $darkest;

    &[disabled] {
      cursor: unset;
      text-decoration: underline;
      color: $darkest;
    }
  }
}
